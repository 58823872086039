import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import HamburgerMenu from "./components/HamburgerMenu/HamburgerMenu";
import ScrollToTop from "./utils/ScrollToTop";
import Spinner from "./components/Spinner/Spinner";
import { ReactComponent as Up } from "./assets/icons/arrow.svg";

function App() {
  const [loaded, setLoaded] = useState(true);
  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoaded(true);
  //   }, 1000);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const handleScroll = (e: any) => {
    const scrollOffset = window.pageYOffset;
    if (scrollOffset > 50) {
      setScrollButtonVisible(true);
    } else {
      setScrollButtonVisible(false);
    }
  };

  const scrollUp = () => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  };
  return loaded ? (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <HamburgerMenu />
        <Routes />
        <Footer />
        <div
          className={`scroll-up ${scrollButtonVisible ? "" : "hide"}`}
          onClick={scrollUp}
        >
          <Up />
        </div>
      </Router>
    </>
  ) : (
    <Spinner />
  );
}

export default App;
