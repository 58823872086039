import "./Services.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import AService from "../../components/Service/AService";
import mainServices from "./mainServices";

const Services = () => {
  const location = useLocation();

  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const anchor = location.hash;
    if (anchor.includes("ugradnja"))
      document
        .querySelector("#Ugradnja")
        ?.scrollIntoView({ behavior: "smooth" });
    if (anchor.includes("reparacija"))
      document
        .querySelector("#Reparacija_i_hoblovanje")
        ?.scrollIntoView({ behavior: "smooth" });
    if (anchor.includes("poliranje"))
      document
        .querySelector("#Poliranje_i_lakiranje")
        ?.scrollIntoView({ behavior: "smooth" });
    if (anchor.includes("lajsne"))
      document
        .querySelector("#Ugradnja_lajsni")
        ?.scrollIntoView({ behavior: "smooth" });
    if (anchor.includes("bajcovanje"))
      document
        .querySelector("#Bajcovanje")
        ?.scrollIntoView({ behavior: "smooth" });
    if (anchor.includes("nivelacija"))
      document
        .querySelector("#Nivelacija_poda")
        ?.scrollIntoView({ behavior: "smooth" });
  }, [location]);

  return (
    <>
      <header className="services-heading">
        <div className={`services-white-surface ${loaded ? "" : "not-loaded"}`}>
          <h1>Usluge</h1>
          <ul>
            <li>
              <a href="#ugradnja">Ugradnja</a>
            </li>
            <li>
              <a href="#reparacija">Reparacija i hoblovanje</a>
            </li>
            <li>
              <a href="#poliranje">Poliranje i lakiranje</a>
            </li>
            <li>
              <a href="#lajsne">Ugradnja lajsni</a>
            </li>
            <li>
              <a href="#bajcovanje">Bajcovanje</a>
            </li>
            <li>
              <a href="#nivelacija">Nivelacija poda</a>
            </li>
          </ul>
        </div>
      </header>
      <section className={`short-description ${loaded ? "" : "not-loaded"}`}>
        <div className="decoration">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <p>
          Prilikom uređenja novog ili adaptacije starog prostora, uglavnom se
          kreće od podova. Podovi su temelj svakog dizajna i prilično utiču na
          celokupan izgled enterijera. Pored lepog izgleda, bitna je i njihova
          dugotrajnost, otpornost i izdržljivost. Zato je važno da dobro
          razmislite pre nego što izaberete novi ili sredite svoj stari pod.
          <br />
          Kod nas možete pronaći kvalitetne usluge i korisne savete koji će Vam
          pomoći da donesete pravu odluku.
        </p>
      </section>

      <section className="service-description">
        {mainServices.map((service, index) => (
          <div
            className="service-container"
            key={service.title}
            id={service.title.split(" ").join("_")}
          >
            <AService
              title={service.title}
              text={service.text}
              topText={service.intro}
              image={service.image}
            />
            {index === mainServices.length - 1 ? null : (
              <div className="separator" style={{ margin: "50px 0" }}></div>
            )}
          </div>
        ))}
      </section>
    </>
  );
};
export default Services;
