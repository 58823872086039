import { EmailJSResponseStatus, init, send } from "emailjs-com";

// const accessToken = "fc3a361e7e2bd1a93a36359c8aa19791";
const userId = "user_ZQedHp5xOPGTcK6Nybndf";
const serviceId = "parket_vukasovic";
const templateId = "template_0gj3qqt";

init(userId);

export function sendEmail(
  name: string,
  email: string,
  title: string,
  content: string
): Promise<EmailJSResponseStatus> {
  return send(
    serviceId,
    templateId,
    {
      to_name: "Vukasović",
      from_name: name,
      reply_to: email,
      message: content,
      subject: title,
    },
    userId
  );
}
