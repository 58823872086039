import { Link } from "react-router-dom";
import Card from "../../components/Card/Card";
import "./AboutUs.css";
import about1 from "../../assets/images/about/o-nama-1.jpg";
import about2 from "../../assets/images/about/o-nama-2.jpg";
import { useEffect, useState } from "react";

const AboutUs = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <header className={`about-heading ${loaded ? "" : "not-loaded"}`}>
        <h1>O nama</h1>
      </header>
      <section className={`story ${loaded ? "" : "not-loaded"}`}>
        <h2>Naša priča</h2>
        <p>
          Našu firmu čini tim sertifikovanih stručnjaka, koji se bavi nabavkom,
          ugradnjom i obradom svih vrsta drvenih podnih obloga. Zahvaljujući
          profesionalnosti i dugogodišnjem iskustvu, u mogućnosti smo da
          odgovorimo svim Vašim željama i zahtevima. Spremni smo da Vam pružimo
          i puno novih ideja, saveta i najboljih rešenja za Vaše podove, bilo da
          je reč o stambenim ili javnim objektima. Poštujemo Vaše vreme i
          poslove obavljamo u dogovorenom roku.
        </p>
      </section>
      <section className="about-section">
        <div className="image-wrap">
          <img src={about1} alt="O Nama" />
        </div>
        <div className="description">
          <h2>Vaše zadovoljstvo je naš uspeh!</h2>
          <p>
            Recept za naš uspeh je dobra organizacija, predan i precizan rad,
            savremena oprema i kvalitetan materijal.
          </p>
        </div>
      </section>
      <section className="about-section">
        <div className="description">
          <h2>Pod je važan deo i osnova svakog enterijera.</h2>
          <p>
            Značajno doprinosi celokupnom izgledu prostora, daje mu karakter i
            čini ga jedinstvenim. Ukoliko razmišljate o ugradnji novog ili
            renoviranju starog poda, na pravom ste mestu.
          </p>
        </div>
        <div className="image-wrap">
          <img src={about2} alt="O Nama" />
        </div>
      </section>
      <section className="qualities">
        <div className="border">
          <div className="content">
            <h2>100%</h2>
            <p>Kvalitet usluge</p>
          </div>
        </div>
        <div className="border">
          <div className="content">
            <h2>500+</h2>
            <p>Zadovoljnih klijenata</p>
          </div>
        </div>
        <div className="border">
          <div className="content">
            <h2>100%</h2>
            <p>Garancija</p>
          </div>
        </div>
      </section>
      <section className="links">
        <Link to="galerija">
          <Card
            title="Naši radovi"
            text="Najbolji način da se uverite u kvalitet naših usluga jeste da pogledate fotografije."
          />
        </Link>
        <Link to="kontakt">
          <Card
            title="Imate pitanja?"
            text="Ukoliko ste se odlučili za neku od naših usluga ili imate dodatnih pitanja, kontaktirajte nas."
          />
        </Link>
      </section>
    </>
  );
};

export default AboutUs;
