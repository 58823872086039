import BeforeAfter from "../../components/BeforeAfter/BeforeAfter";
import "./Gallery.css";
import CustomCarousel from "../../components/Carousel/Carousel";

// Before and after
import before1 from "../../assets/images/gallery/beforeAfter/before1.jpg";
import after1 from "../../assets/images/gallery/beforeAfter/after1.jpg";
import before2 from "../../assets/images/gallery/beforeAfter/before2.jpg";
import after2 from "../../assets/images/gallery/beforeAfter/after2.jpg";
import before3 from "../../assets/images/gallery/beforeAfter/before3.jpeg";
import after3 from "../../assets/images/gallery/beforeAfter/after3.jpg";
import before4 from "../../assets/images/gallery/beforeAfter/before4.jpg";
import after4 from "../../assets/images/gallery/beforeAfter/after4.jpg";
import before5 from "../../assets/images/gallery/beforeAfter/before5.jpg";
import after5 from "../../assets/images/gallery/beforeAfter/after5.jpg";
import before6 from "../../assets/images/gallery/beforeAfter/before6.jpg";
import after6 from "../../assets/images/gallery/beforeAfter/after6.jpg";
import before7 from "../../assets/images/gallery/beforeAfter/before7.jpg";
import after7 from "../../assets/images/gallery/beforeAfter/after7.jpg";
import before8 from "../../assets/images/gallery/beforeAfter/before8.jpg";
import after8 from "../../assets/images/gallery/beforeAfter/after8.jpg";
import before9 from "../../assets/images/gallery/beforeAfter/before9.jpg";
import after9 from "../../assets/images/gallery/beforeAfter/after9.jpeg";

// Ugradnja
import ugradnja1 from "../../assets/images/gallery/ugradnja/1.jpg";
import ugradnja2 from "../../assets/images/gallery/ugradnja/2.jpg";
import ugradnja3 from "../../assets/images/gallery/ugradnja/3.jpg";
import ugradnja4 from "../../assets/images/gallery/ugradnja/4.jpg";
import ugradnja5 from "../../assets/images/gallery/ugradnja/5.jpg";
import ugradnja6 from "../../assets/images/gallery/ugradnja/6.jpg";
import ugradnja7 from "../../assets/images/gallery/ugradnja/7.jpg";
import ugradnja8 from "../../assets/images/gallery/ugradnja/8.jpg";
import ugradnja9 from "../../assets/images/gallery/ugradnja/9.jpg";
import ugradnja10 from "../../assets/images/gallery/ugradnja/10.jpeg";
import ugradnja11 from "../../assets/images/gallery/ugradnja/11.jpeg";
import ugradnja12 from "../../assets/images/gallery/ugradnja/12.jpg";
import ugradnja13 from "../../assets/images/gallery/ugradnja/13.jpg";

// Reparacija i hoblovanje
import reparacija1 from "../../assets/images/gallery/reparacija/1.jpg";
import reparacija2 from "../../assets/images/gallery/reparacija/2.jpg";
import reparacija3 from "../../assets/images/gallery/reparacija/3.jpg";
import reparacija4 from "../../assets/images/gallery/reparacija/4.jpg";
import reparacija6 from "../../assets/images/gallery/reparacija/6.jpg";
import reparacija7 from "../../assets/images/gallery/reparacija/7.jpg";
import reparacija8 from "../../assets/images/gallery/reparacija/8.jpg";
import reparacija9 from "../../assets/images/gallery/reparacija/9.jpg";
import reparacija12 from "../../assets/images/gallery/reparacija/12.jpg";
import reparacija13 from "../../assets/images/gallery/reparacija/13.jpg";
import reparacija14 from "../../assets/images/gallery/reparacija/14.jpg";

// Poliranje i lakiranje
import poliranje1 from "../../assets/images/gallery/poliranje/1.jpg";
import poliranje2 from "../../assets/images/gallery/poliranje/2.jpg";
import poliranje3 from "../../assets/images/gallery/poliranje/3.jpg";
import poliranje4 from "../../assets/images/gallery/poliranje/4.jpg";
import poliranje5 from "../../assets/images/gallery/poliranje/5.jpg";
import poliranje6 from "../../assets/images/gallery/poliranje/6.jpg";
import poliranje7 from "../../assets/images/gallery/poliranje/7.jpg";
import poliranje8 from "../../assets/images/gallery/poliranje/8.jpg";
import poliranje9 from "../../assets/images/gallery/poliranje/9.jpg";
import poliranje10 from "../../assets/images/gallery/poliranje/10.jpg";
import poliranje11 from "../../assets/images/gallery/poliranje/11.jpg";
import poliranje12 from "../../assets/images/gallery/poliranje/12.jpg";

// Ugradnja lajsni
import lajsne1 from "../../assets/images/gallery/lajsne/1.jpg";
import lajsne2 from "../../assets/images/gallery/lajsne/2.jpg";
import lajsne3 from "../../assets/images/gallery/lajsne/3.jpg";
import lajsne4 from "../../assets/images/gallery/lajsne/4.jpg";
import lajsne5 from "../../assets/images/gallery/lajsne/5.jpg";
import lajsne6 from "../../assets/images/gallery/lajsne/6.jpg";
import lajsne7 from "../../assets/images/gallery/lajsne/7.jpg";

// Bajcovanje
import bajcovanje1 from "../../assets/images/gallery/bajcovanje/1.jpg";
import bajcovanje2 from "../../assets/images/gallery/bajcovanje/2.jpg";
import bajcovanje3 from "../../assets/images/gallery/bajcovanje/3.jpg";
import bajcovanje4 from "../../assets/images/gallery/bajcovanje/4.jpg";
import bajcovanje5 from "../../assets/images/gallery/bajcovanje/5.jpg";
import bajcovanje6 from "../../assets/images/gallery/bajcovanje/6.jpg";
import bajcovanje7 from "../../assets/images/gallery/bajcovanje/7.jpg";

// Nivelacija
import nivelacija1 from "../../assets/images/gallery/nivelacija/1.jpg";
import nivelacija2 from "../../assets/images/gallery/nivelacija/2.jpg";
import nivelacija3 from "../../assets/images/gallery/nivelacija/3.jpg";
import nivelacija4 from "../../assets/images/gallery/nivelacija/4.jpg";
import nivelacija5 from "../../assets/images/gallery/nivelacija/5.jpg";
import nivelacija6 from "../../assets/images/gallery/nivelacija/6.jpg";
import nivelacija7 from "../../assets/images/gallery/nivelacija/7.jpg";
import nivelacija8 from "../../assets/images/gallery/nivelacija/8.jpg";
import { useEffect, useState } from "react";

const Gallery = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getBeforeAfters = () => {
    return [
      <BeforeAfter before={before1} after={after1} />,
      <BeforeAfter before={before2} after={after2} />,
      <BeforeAfter before={before3} after={after3} />,
      <BeforeAfter before={before4} after={after4} />,
      <BeforeAfter before={before5} after={after5} />,
      <BeforeAfter before={before6} after={after6} />,
      <BeforeAfter before={before7} after={after7} />,
      <BeforeAfter before={before8} after={after8} />,
      <BeforeAfter before={before9} after={after9} />,
    ];
  };

  const ugradnja = () => {
    return [
      <img className="img-service" src={ugradnja1} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja2} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja3} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja4} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja5} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja6} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja7} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja8} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja9} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja10} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja11} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja12} alt="ugradnja"></img>,
      <img className="img-service" src={ugradnja13} alt="ugradnja"></img>,
    ];
  };
  const reparacija = () => {
    return [
      <img className="img-service" src={reparacija1} alt="reparacija"></img>,
      <img className="img-service" src={reparacija2} alt="reparacija"></img>,
      <img className="img-service" src={reparacija3} alt="reparacija"></img>,
      <img className="img-service" src={reparacija4} alt="reparacija"></img>,
      <img className="img-service" src={reparacija6} alt="reparacija"></img>,
      <img className="img-service" src={reparacija7} alt="reparacija"></img>,
      <img className="img-service" src={reparacija8} alt="reparacija"></img>,
      <img className="img-service" src={reparacija9} alt="reparacija"></img>,
      <img className="img-service" src={reparacija12} alt="reparacija"></img>,
      <img className="img-service" src={reparacija13} alt="reparacija"></img>,
      <img className="img-service" src={reparacija14} alt="reparacija"></img>,
    ];
  };
  const poliranje = () => {
    return [
      <img className="img-service" src={poliranje1} alt="poliranje"></img>,
      <img className="img-service" src={poliranje2} alt="poliranje"></img>,
      <img className="img-service" src={poliranje3} alt="poliranje"></img>,
      <img className="img-service" src={poliranje4} alt="poliranje"></img>,
      <img className="img-service" src={poliranje5} alt="poliranje"></img>,
      <img className="img-service" src={poliranje6} alt="poliranje"></img>,
      <img className="img-service" src={poliranje7} alt="poliranje"></img>,
      <img className="img-service" src={poliranje8} alt="poliranje"></img>,
      <img className="img-service" src={poliranje9} alt="poliranje"></img>,
      <img className="img-service" src={poliranje10} alt="poliranje"></img>,
      <img className="img-service" src={poliranje11} alt="poliranje"></img>,
      <img className="img-service" src={poliranje12} alt="poliranje"></img>,
    ];
  };
  const lajsne = () => {
    return [
      <img className="img-service" src={lajsne1} alt="ugradnja lajsni"></img>,
      <img className="img-service" src={lajsne7} alt="ugradnja lajsni"></img>,
      <img className="img-service" src={lajsne3} alt="ugradnja lajsni"></img>,
      <img className="img-service" src={lajsne4} alt="ugradnja lajsni"></img>,
      <img className="img-service" src={lajsne5} alt="ugradnja lajsni"></img>,
      <img className="img-service" src={lajsne6} alt="ugradnja lajsni"></img>,
      <img className="img-service" src={lajsne2} alt="ugradnja lajsni"></img>,
    ];
  };
  const bajcovanje = () => {
    return [
      <img className="img-service" src={bajcovanje1} alt="bajcovanje"></img>,
      <img className="img-service" src={bajcovanje2} alt="bajcovanje"></img>,
      <img className="img-service" src={bajcovanje3} alt="bajcovanje"></img>,
      <img className="img-service" src={bajcovanje4} alt="bajcovanje"></img>,
      <img className="img-service" src={bajcovanje5} alt="bajcovanje"></img>,
      <img className="img-service" src={bajcovanje6} alt="bajcovanje"></img>,
      <img className="img-service" src={bajcovanje7} alt="bajcovanje"></img>,
    ];
  };
  const nivelacija = () => {
    return [
      <img className="img-service" src={nivelacija1} alt="nivelacija"></img>,
      <img className="img-service" src={nivelacija2} alt="nivelacija"></img>,
      <img className="img-service" src={nivelacija3} alt="nivelacija"></img>,
      <img className="img-service" src={nivelacija4} alt="nivelacija"></img>,
      <img className="img-service" src={nivelacija5} alt="nivelacija"></img>,
      <img className="img-service" src={nivelacija6} alt="nivelacija"></img>,
      <img className="img-service" src={nivelacija7} alt="nivelacija"></img>,
      <img className="img-service" src={nivelacija8} alt="nivelacija"></img>,
    ];
  };

  return (
    <div className={`gallery ${loaded ? "" : "not-loaded"}`}>
      <header>
        <h1>Galerija</h1>
      </header>
      <section className="intro">
        <h2>Naši radovi</h2>
        <p>
          Na fotografijama možete pogledati kako teku naši radovi i krajnje
          rezultate, koji su nas doveli do niza uspešno obavljenih poslova i
          mnogo zadovoljnih klijenata.
        </p>
      </section>

      <h2 className="before-after-title">Pre i posle</h2>
      <p className="before-after-text">
        Najbolji pokazatelj sjajnih rezultata našeg truda i rada su fotografije
        podova pre i nakon sređivanja.
      </p>
      <section>
        <CustomCarousel type="ba" items={getBeforeAfters()} />
      </section>

      <h2 className="title">Ugradnja</h2>
      <p className="before-after-text">
        Ugradnja svih vrsta drvenih podnih obloga - klasičnih i gotovih parketa,
        brodskih podova, laminata.
      </p>
      <section>
        <CustomCarousel type="u" items={ugradnja()} />
      </section>

      <h2 className="title">Reparacija i hoblovanje</h2>
      <p className="before-after-text">
        Popravka starog parketa, zamena oštećenih delova, učvršćivanje,
        brušenje, popunjavanje fugni i pukotina.
      </p>
      <section>
        <CustomCarousel type="r" items={reparacija()} />
      </section>

      <h2 className="title">Poliranje i lakiranje</h2>
      <p className="before-after-text">
        Mašinsko poliranje parketa do savršeno glatke površine, spremne za
        lakiranje. Lakiranje različitim vrstama lakova sa sjajnim, polumat ili
        mat završnim efektom.
      </p>
      <section>
        <CustomCarousel type="p" items={poliranje()} />
      </section>

      <h2 className="title">Ugradnja lajsni</h2>
      <p className="before-after-text">
        Postavljanje lajsni svih vrsta, veličina i materijala. Silikoniranje
        lajsni sa gornje i donje strane.
      </p>
      <section>
        <CustomCarousel type="l" items={lajsne()} />
      </section>

      <h2 className="title">Bajcovanje</h2>
      <p className="before-after-text">
        Tehnika nijansiranja parketa pri kojoj se menja boja drveta, a njegova
        tekstura ostaje ista. Moguća je promena boje starog i novog parketa.
      </p>
      <section>
        <CustomCarousel type="b" items={bajcovanje()} />
      </section>

      <h2 className="title">Nivelacija poda</h2>
      <p className="before-after-text">
        Izlivanje samonivelišuće mase kojom se ravna podloga pre ugradnje podnih
        obloga.
      </p>
      <section>
        <CustomCarousel type="n" items={nivelacija()} />
      </section>
    </div>
  );
};

export default Gallery;
