import "./Card.css";

type CardSizeType = "small";
interface CardProps {
  title: string;
  text: string;
  size?: CardSizeType;
}

const Card = (props: CardProps) => {
  const { title, text, size = "small" } = props;
  return (
    <div className={`card ${size}`}>
      <h3>{title}</h3>
      <p>{text}</p>
      <span className="enter"></span>
    </div>
  );
};

export default Card;
