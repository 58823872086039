import "./Button.css";

export type ButtonIconType = "enter" | "gallery";
interface ButtonProps {
  size?: "small" | "big";
  text: string;
  color?: "dark" | "primary";
  icon?: ButtonIconType;
  type?: "button" | "submit";
}
const Button = (props: ButtonProps) => {
  const {
    text,
    color = "primary",
    icon,
    type = "button",
    size = "big",
  } = props;
  return (
    <button type={type} className={`${size} ${color} ${icon ? "icon" : ""}`}>
      <span className="text">{text}</span>
      {icon ? <span className={`${icon}`}></span> : null}
    </button>
  );
};

export default Button;
