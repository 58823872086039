import ImageSlider from "react-image-comparison-slider";
import "./BeforeAfter.css";

interface BeforeAfterProps {
  before: string;
  after: string;
}
const BeforeAfter = (props: BeforeAfterProps) => {
  const { before, after } = props;
  const mobileStyle = { width: 350, height: 250 };
  const desktopStyle = { width: 600, height: 450 };
  // eslint-disable-next-line no-restricted-globals
  const isMobile = screen.width < 767.98;
  return (
    <div
      className="slider-container"
      style={isMobile ? mobileStyle : desktopStyle}
    >
      <ImageSlider
        image1={after}
        alt1="after"
        image2={before}
        alt2="before"
        handleColor="#847767"
        sliderColor="#847767"
        sliderWidth={5}
      />
    </div>
  );
};

export default BeforeAfter;
