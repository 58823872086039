import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import logo from "../../assets/logotip.png";
import { useState } from "react";

const Navbar = () => {
  const [isSubmenuShown, setSubmenuShown] = useState<boolean>(false);
  const location = useLocation();

  return (
    <nav className="navbar">
      <Link
        to="/o-nama"
        className={`${location.pathname === "/" ? "white" : ""}`}
      >
        O nama
      </Link>
      <span
        onMouseOver={() => setSubmenuShown(true)}
        onMouseLeave={() => setSubmenuShown(false)}
        className={`${isSubmenuShown ? "expand" : ""}`}
      >
        <Link
          to="/usluge"
          className={`${location.pathname === "/" ? "white" : ""}`}
        >
          Usluge
        </Link>
        <div className="submenu">
          <Link to={{ pathname: "usluge", hash: "ugradnja" }}>Ugradnja</Link>
          <Link to={{ pathname: "usluge", hash: "reparacija" }}>
            Reparacija i hoblovanje
          </Link>
          <Link to={{ pathname: "usluge", hash: "poliranje" }}>
            Poliranje i lakiranje
          </Link>
          <Link to={{ pathname: "usluge", hash: "lajsne" }}>
            Ugradnja lajsni
          </Link>
          <Link to={{ pathname: "usluge", hash: "bajcovanje" }}>
            Bajcovanje
          </Link>
          <Link to={{ pathname: "usluge", hash: "nivelacija" }}>
            Nivelacija poda
          </Link>
        </div>
      </span>
      <Link to="/" className="logo">
        <img src={logo} alt="Početna" />
      </Link>
      <Link to="/galerija">Galerija</Link>
      <Link to="/kontakt">Kontakt</Link>
    </nav>
  );
};

export default Navbar;
