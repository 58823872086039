import UgradnjaImg from "../../assets/images/services/Ugradnja.jpg";
import ReparacijaImg from "../../assets/images/services/reparacijaihoblovanje.jpg";
import PoliranjeImg from "../../assets/images/services/poliranjeilakiranje.jpg";
import UgradnjaLajsniImg from "../../assets/images/services/ugradnjalajsni.jpg";
import BajcovanjeImg from "../../assets/images/services/bajcovanje.jpeg";
import NivelacijaImg from "../../assets/images/services/nivelacijapoda.jpg";

const mainServices = [
  {
    title: "Ugradnja",
    text: (
      <p>
        Ukoliko se odlučite za ugradnju
        <span className="accent"> klasičnog parketa</span>, možete birati
        veličinu daščica i tip slaganja poput riblje kosti, mozaika, francuskog
        sloga, paralelnog, naizmeničnog sloga, različite bordure. Nakon
        postavke, neophodna je i završna obrada drveta, odnosno hoblovanje,
        popunjavanje fugni, poliranje i lakiranje.
        <br />
        <br />
        Pored klasičnog parketa, sve popularniji su i
        <span className="accent"> gotovi, višeslojni parketi</span>. Oni su
        fabrički obrađeni pa ih zbog toga nije potrebno hoblovati i lakirati
        nakon ugradnje. Moguće ih je ugraditi lepljenjem ili takozvanim
        “plivajućim“ postavljanjem. U tom slučaju se daske uklapaju bez
        lepljenja, klik sistemom. Ovi podovi su veoma izdržljivi, kvalitetni i
        dugotrajni.
        <br />
        <br />
        <span className="accent">Masivni pod</span> je napravljen od punog
        drveta, a dimenzije se kreću od 15mm do 20mm debljine, od 90mm do 200mm
        širine i od 500mm do 2000mm dužine. Kao što možete primetiti, masivni
        drveni podovi spadaju u podne obloge većih dimenzija. Odlično se
        uklapaju u svaki enterijer, starinjski ili moderno uređen. Ugrađuju se
        lepljenjem palica na najkvalitetniji dvokomponentni lepak. Mogu biti
        fabrički obrađeni, a mogu i zahtevati obradu nakon ugradnje. Završna
        zaštita masiva može da bude lak, ulje, vosak. Podovi od masivnog drveta
        su izuzetno dugotrajni, izdržljivi i mogu se koristiti generacijama.
        <br />
        <br />
        <span className="accent">Laminat </span> je podna obloga koja je
        prilično otporna na habanje, ogrebotine i mrlje. Izuzetno je popularan
        zbog veoma pristupačnih cena, velikog izbora boja i dezena. Pored toga,
        laminat je i veoma jednostavan za održavanje. Moguće ga je postaviti na
        različite podloge poput betona, parketa, pločica, pvc podova, starog
        laminata. Ugrađuje se uklapanjem kao “plivajući pod“, bez lepljenja.
        Mana ovog poda je to što ga je nemoguće popraviti, osvežiti, zameniti
        oštećene delove novim, za razliku od parketa.
      </p>
    ),
    intro:
      "Ugradnja poda se vrši nakon detaljne pripreme podloge, od čeka veoma zavisi njegova dugotrajnost i kvalitet. Podloga treba da bude ravna, suva i potpuno očišćena od prašine. Vlaga se meri profesionalnim aparatima - vlagomerima, a preporučuje se da ona bude ispod 2%.",
    image: UgradnjaImg,
  },
  {
    title: "Reparacija i hoblovanje",
    text: (
      <div>
        <p>
          Često je neophodna <span className="accent"> popravka parketa </span>
          nakon poplava, prilikom kojih se parket podigne. U tom slučaju parket
          može da se zameni u potpunosti, a mogu se zameniti samo podignuti i
          oštećeni delovi. Neizostavan deo reparacije je i
          <span className="accent"> hoblovanje</span>, tj. površinska obrada
          parketa. Drvo vremenom stari, dolazi do raznih ogrebotina, habanja,
          gubitka sjaja. Postupak hoblovanja se sastoji iz nekoliko faza:
        </p>
        <ul>
          <li>Grubo šmirglanje</li>
          <li>
            Popunjavanje fugni ili nekih pukotina u parketu. Za to se koristi
            fug masa koja se pomeša sa piljevinom, dobijenom prilikom
            hoblovanja. Ukoliko su fugne veoma široke, ovaj postupak se ponavlja
            nekoliko puta.
          </li>
          <li>Nakon toga sledi fino šmirglanje</li>
        </ul>
        <p>
          Veoma je bitno da se parket detaljno usisa i očisti od prašine nakon
          hoblovanja. Tada je spreman za poslednje dve faze, poliranje i
          lakiranje. Mnogi se odlučuju i za promenu boje drveta, što se uspešno
          može postići bajcovanjem parketa. U nastavku možete pročitati više o
          tome.
        </p>
      </div>
    ),
    intro:
      "Prednost parketa u odnosu na ostale vrste podnih obloga je to što ga je moguće osvežiti, vratiti mu lep izgled i izgubljeni sjaj. Pod reparacijom, odnosno popravkom parketa se podrazumeva zamena oštećenih i rasušenih delova i učvršćivanje odlepljenog parketa, koji obično škripi pod nogama.",
    image: ReparacijaImg,
  },
  {
    title: "Poliranje i lakiranje",
    text: (
      <p>
        Nakon detaljne pripreme parketa, nanose se tri ili četiri sloja laka.
        Između svakog od njih postoji određeni period sušenja, koji se mora
        ispoštovati. Prvo se nanosi <span className="accent"> osnovni lak</span>
        , a on se bira prema vrsti drveta i vrsti završnog laka. Zatim se nanosi
        <span className="accent"> završni lak</span>, dva ili tri puta. Bitno je
        napomenuti da se parket između svakog sloja laka polira i detaljno
        usisava, kako bi završni sloj izgledao estetski savršeno, bez bilo
        kakvih tragova i mrlja.
        <br />
        <br />
        Danas postoji mnogo vrsta lakova. Prema sastavu se dele na
        <span className="accent"> jednokomponentne</span> i
        <span className="accent"> dvokomponentne</span>, a mogu biti na bazi
        vode, ulja, nitno, akrilni i poliuretanski. U poslednje vreme, izuzetno
        su popularni lakovi na vodenoj bazi jer su ekološki prihvatljivi, ne
        sadrže štetne materije i neprijatne mirise. Vi sami birate vrstu laka i
        završni efekat koji može da bude sjajan, polu – sjajan ili mat.
        <br />
        <br />
        Pored toga što ističu lepotu drveta, lakovi ga
        <span className="accent"> štite od ogrebotina</span> i raznih oštećenja.
        Veoma je bitan i način održavanja parketa. Treba izbegavati grube četke
        i razne hemikalije prilikom čišćenja, a koristiti meke sunđere i krpe.
        Na taj način ćete održati njegovu lepotu i sjaj.
        <br />
        <br />
        Često nas pitaju -<i>da li je moguće lakirati parket bez hoblovanja?</i>
        Mi kažemo da je moguće, ali pod određenim uslovima. Obično svi čekaju da
        prođu decenije kako bi sredili svoj parket, tada već prilično star i
        uništen. Njemu je potrebno redovno održavanje. Ako je parket u dobrom
        stanju, ali bez sjaja i sa sitnim ogrebotinama, vreme je za malo
        osveženje. Dovoljno mu je samo poliranje i lakiranje kako bi ponovo
        zablistao u punom sjaju.
      </p>
    ),
    intro:
      "Nakon hoblovanja, parket se detaljno polira i tako priprema za završnu fazu – lakiranje. Kada je poliranje gotovo, parket se usisava i  potpuno čisti od prašine. Tada je spreman za nanošenje zaštitnog sloja laka, voska ili ulja. U velikoj većini slučajeva se bira lak. Povoljna temperatura za rad je između 18-22⁰C, a vlažnost vazduha između 50-60%.",
    image: PoliranjeImg,
  },
  {
    title: "Ugradnja lajsni",
    text: (
      <p>
        <ul>
          <li>Zaštita zidova od oštećenja</li>
          <li>Prikrivanje kablova</li>
          <li>Prekrivanje praznog prostora između podova i zidova</li>
          <li>Lakše održavanje podova</li>
        </ul>
        Prilikom izbora lajsni za parket, treba obratiti pažnju na{" "}
        <span className="accent">estetiku</span>, ali i na{" "}
        <span className="accent">funkcionalnost</span>. Oblik i visina lajsni se
        uglavnom usklađuju sa stilom enterijera. Postoje klasične lajsne za
        parket i sokle, tj. cokle.
        <br />
        <br />
        <span className="accent">Klasične lajsne</span> za parket se proizvode
        od drveta hrasta, jasena i bukve. Postoji veliki broj različitih vrsta
        klasičnih lajsni, kako bi se mogle uklopiti u sve vrste parketa, od
        klasičnih boja i vrsta pa sve do modernijih varijanti, raznih nijansi.
        <br />
        <br />
        Sve popularnije su i <span className="accent">sokle</span>, visoke
        lajsne, koje se obično postavljaju u kombinaciji sa viner lajsnom. Sokle
        se takođe prave od drveta hrasta, jasena i bukve. Najčešće visine ovih
        lajsni su 6cm, 7cm i 8cm.
        <br />
        <br />
        Bele, visoke lajsne za parket su izuzetno popularne i tražene u
        poslednje vreme. One prostoru daju elegantan i prefinjen izgled. Visina
        lajsni se kreće od <span className="accent">6-14cm</span>. Najčešće se
        izrađuju od <span className="accent">medijapana</span>, ali se mogu
        izraditi i od <span className="accent">stirodura</span>. Široki ili
        uski, moderni ili starinjski, pravi ili iskrivljeni, svi mdf profili
        imaju vrlo kvalitetnu površinu koja se može farbati. Mdf lajsne mogu
        biti presvučene folijom ili farbane, a isto tako ih možete obojiti u
        željenu boju. Mana ovih lajsni je to što ih nije moguće popraviti nakon
        oštećenja i što se spojevi na sastavu dve lajsne vide. Za razliku od
        njih, lajsne od stirodura se farbaju nakon postavke u željenu boju, a
        spojevi na sastavu se gituju pa samim tim nisu vidljivi.
        <br />
        <br />
        Ukoliko imate laminat, vinil ili neku drugu vrstu poda, lajsne su takođe
        neophodan završni korak kako bi se zaštitili zidovi i popunila praznina
        između poda i zida. U zavisnosti od vrste podne obloge, postoje i
        različite vrste lajsni poput medijapan lajsne, PVC lajsne, aluminijumske
        lajsne, PVC trake, itd.
        <br />
        <br />
        Lajsne se postavljaju uz zid, jedna uz drugu. Spojevi se uglavnom
        geruju, seku pod određenim uglom, a zatim precizno uklapaju. Lepak se
        nanosi na lajsnu, koja se zatim lepi na zid uz blagi pritisak. Ukoliko
        je zid kriv, pojaviće se pukotina između lajsne i zida. To se vrlo lako
        može rešiti uz pomoć akrilnog silikona, kojim se pukotine ispunjavaju i
        koji se gotovo i ne primećuje. Ukoliko postoji zazor sa donje strane,
        između lajsne i poda, nanosi se silikon u boji lajsne.
      </p>
    ),
    intro:
      "Mali detalji prave veliku razliku! Lajsne predstavljaju važan elemenat enterijera i savršen završni dodatak Vašem podu. Pored toga što doprinose lepoti prostorije, njihove funkcije su sledeće:",
    image: UgradnjaLajsniImg,
  },
  {
    title: "Bajcovanje",
    text: (
      <p>
        Postupak <span className="accent">bajcovanja</span> se radi nakon{" "}
        <span className="accent">hoblovanja</span> parketa. Po završetku
        hoblovanja, prašina se detaljno i pažljivo uklanja. Bajc se ravnomerno
        premazuje preko parketa, a pamučnom krpom se uklanja višak bajca. Veoma
        je bitno da se bajc dobro promeša tokom nanošenja, jer može da se desi
        da je prilično gust i da se zadržava na dnu. Premazivanje parketa se
        može ponoviti nekoliko puta, sve dok se ne postigne željena boja. Kada
        se bajc osuši, sledi <span className="accent">lakiranje</span>. U
        zavisnosti od čvrstine i tvrdoće drveta, zavisi i količina bajca koja se
        upija. Čvrsta drva, poput hrasta, imaju zonu čvorova koji imaju veću moć
        upijanja i samim tim će izgledati tamnije. Ali takođe, za čvršće vrste
        drveta važi pravilo da generalno imaju manju moć upijanja. Ukoliko se
        neiskusno rukuje bajcom, mogu se pojaviti mrlje i nejednakosti na
        površini prilikom sušenja.
        <br />
        <br />
        Boja može da se promeni i na novom, ali i na starom parketu. Uobičajene
        nijanse jesu svetlije ili tamnije braon, što je približnije prirodnoj
        boji drveta. Međutim, neretko se biraju i različite nijanse sive, bele
        pa sve do jarkih boja, crvene, zelene, plave, srebrne, zlatne.
        <br />
        <br />
        Krajnji rezultat, tj. konačna boja, ton i izgled parketa, zavisi od
        načina na koji je <span className="accent">postupak izveden</span>,{" "}
        <span className="accent">vrste drveta</span> i{" "}
        <span className="accent">vrste bajca</span>. Iskustvo u radu je presudno
        da bi finalni efekat bio dobar. Treba paziti na potrebnu količinu bajca,
        mešanje, ravnomerno nanošenja, sušenje i još mnogo toga, kako ne bi
        došlo do grašaka, koje bi dovele do mrlja i diskoloracije.
      </p>
    ),
    intro:
      "Bajcovanje je postupak kojim se dobija određena boja i nijansa parketa, a struktura drveta ostaje ista.  Ovim postupkom se može istaći prirodna lepota drveta i njegovih šara. Samo bajcovanje je veoma zahtevna tehnika promene boje parketa i zbog toga treba da ga radi stručan majstor. ",
    image: BajcovanjeImg,
  },
  {
    title: "Nivelacija poda",
    text: (
      <p>
        <span className="accent">Samonivelišuća masa</span> se koristi za
        ravnanje podloge pre ugranje podnih obloga. Ona može da se izliva na
        staru podlogu ili na ostatke lepka, na manje ili veće pukotine.
        <br />
        <br />
        Pre svega, pod se detaljno čisti i usisava, kako bi bilo što manje
        prašine. Zatim se valjkom nanosi <span className="accent">
          podloga
        </span>{" "}
        (prajmer), koji služi da se masa za ravnanje bolje veže za pod. Nakon
        sušenja, sledi izlivanje samonivelišuće mase, koja se prvo pomeša sa
        određenom količinom vode. Kada se postigne odgovarajuća gustina, masa se
        lepo raspoređuje po površini poda. Nakon određenog perioda sušenja,
        parket i ostale vrste podova mogu da se postavljaju na podlogu.
        <br />
        <br />
        Visina koju može da pokrije nivelišući pod je od{" "}
        <span className="accent">1mm</span> do{" "}
        <span className="accent">20mm</span>. Ukoliko je površina poda dosta
        neravna i oštećena, a pukotine prilično duboke, preporučuju se
        kvalitetniji materijali, kako ne bi došlo do pucanja. Podovi obrađeni
        ovom masom su <span className="accent">čvrsti</span>,{" "}
        <span className="accent">otporni na oštećenja</span> i odlična su
        podloga za parkete i ostale podne obloge.
      </p>
    ),
    intro:
      "Površina betonskog poda i cementne košuljice je najčešće neravna, što komplikuje postavljanje podnih obloga. Neophodno je da podloga bude ravna, čvrsta i glatka. Ukoliko ste podigli stari parket pa je cementna košuljica ostala neravna ili Vam je možda potrebna idealno ravna podloga za parket, laminat ili neku drugu vrstu podnih obloga, postoji odlično rešenje – samonivelišuća masa.",
    image: NivelacijaImg,
  },
];

export default mainServices;
