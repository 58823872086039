import "./Contact.css";
import { ReactComponent as Email } from "../../assets/icons/mail.svg";
import { ReactComponent as Location } from "../../assets/icons/location.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import TextField from "../../components/TextField/TextField";
import Button from "../../components/Button/Button";
import { sendEmail } from "../../services/email-service";
import { useEffect, useState } from "react";
import { ReactComponent as Check } from "../../assets/icons/check.svg";
import Spinner from "../../components/Spinner/Spinner";

interface ContactFormData {
  name: string;
  email: string;
  title: string;
  content: string;
}
const defaultData = {
  name: "",
  email: "",
  title: "",
  content: "",
};
const Contact = () => {
  const [data, setData] = useState<ContactFormData>(defaultData);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }, [success]);
  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 5000);
  }, [error]);

  const submit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    sendEmail(data.name, data.email, data.title, data.content)
      .then((res) => {
        setData(defaultData);
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      <header className={`contact-header ${loaded ? "" : "not-loaded"}`}>
        <h1>Kontakt</h1>
      </header>
      <div className={`message bubble ${loaded ? "" : "not-loaded"}`}>
        <p>
          Potrebna Vam je neka od naših usluga ili samo imate pitanje?
          <b> Kontaktirajte nas!</b>
        </p>
      </div>
      <div className={`contact-methods ${loaded ? "" : "not-loaded"}`}>
        <div className="contact-card">
          <h4>Kontaktirajte nas putem:</h4>
          <div className="item">
            <Email />
            <p className="text">
              <a href="mailto: parketvukasovic@gmail.com">
                parketvukasovic@gmail.com
              </a>
            </p>
          </div>
          <div className="item">
            <Phone />
            <p className="text">
              <a href="tel:+381628655652">062/865-56-52</a>
            </p>
          </div>
          <div className="item">
            <Location />
            <p className="text">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.rs/maps/place/Kornelija+Stankovića+29,+Novi+Sad/@45.2612468,19.8099698,17z/data=!3m1!4b1!4m5!3m4!1s0x475b11b231597dff:0x3439579e0219323b!8m2!3d45.261243!4d19.8121585"
              >
                Kornelija Stankovića 29, Novi Sad
              </a>
            </p>
          </div>
          <h4>Potražite nas na društvenim mrežama:</h4>
          <div className="socials">
            <a
              href="https://www.instagram.com/parket_vukasovic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://www.facebook.com/parketvukasovic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
          </div>
          <h4>Ili nam pišite pomoću kontakt forme.</h4>
        </div>
        <div className="contact-form bubble">
          <form onSubmit={submit}>
            <TextField
              label="Ime i prezime"
              required
              value={data.name}
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
            <TextField
              label="E-Mail adresa"
              inputType="email"
              required
              value={data.email}
              onChange={(e) => setData({ ...data, email: e.target.value })}
            />
            <TextField
              label="Naslov poruke"
              required
              value={data.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
            />
            <TextField
              label="Tekst poruke"
              type="textfield"
              required
              value={data.content}
              onChange={(e) => setData({ ...data, content: e.target.value })}
            />
            <span className={`success ${success ? "" : "hide"}`}>
              <Check />
              Poruka uspešno poslata!
            </span>
            <span className={`error ${error ? "" : "hide"}`}>
              Došlo je do greške. Pokušajte ponovo.
            </span>
            <span className={`loading ${loading ? "" : "hide"}`}>
              <Spinner />
              <p>Poruka se šalje.</p>
            </span>
            <Button text="Pošalji" color="dark" type="submit" />
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
