import "./Home.css";
import segment1 from "../../assets/images/parket-home.png";
import { ReactComponent as Vukasovic } from "../../assets/potpis.svg";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import { services } from "./homeServices";
import gal1 from "../../assets/images/home/gallery-1.png";
import gal2 from "../../assets/images/home/gallery-2.png";
import gal3 from "../../assets/images/home/gallery-3.png";
import gal4 from "../../assets/images/home/gallery-4.png";
import gal5 from "../../assets/images/home/gallery-5.png";
import gal6 from "../../assets/images/home/gallery-6.png";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <>
      <header className={`home-header ${loaded ? "" : "not-loaded"}`}>
        <img className="segment" src={segment1} alt="parket" />

        <h1>PARKET</h1>
        <Vukasovic />
        <div className="white-surface">
          <h1>SERVIS</h1>
          <h3>
            Svako ko je pod nogama osetio udobnost i toplinu drveta, nikada neće
            poželeti da ostane bez tog osećaja!
          </h3>
          <Link to="kontakt">
            <Button text="Kontakt" icon="enter"></Button>
          </Link>
        </div>
      </header>

      <section className="o-nama">
        <div className="description">
          <h2>O nama</h2>
          <p>
            Našu firmu čini tim sertifikovanih stručnjaka, koji se bavi
            nabavkom, ugradnjom i obradom svih vrsta drvenih podnih obloga.
            Zahvaljujući profesionalnosti i dugogodišnjem iskustvu, u mogućnosti
            smo da odgovorimo svim Vašim željama i zahtevima.
          </p>
          <Link to="o-nama">
            <Button text="Vidi više" icon="enter" />
          </Link>
        </div>
        <div className="image"></div>
      </section>

      <section className="usluge">
        <h2>Naše usluge</h2>
        <div>
          {services.map((service) => (
            <Link
              to={{ pathname: "usluge", hash: service.name }}
              key={service.title}
            >
              <Card title={service.title} text={service.text} />
            </Link>
          ))}
        </div>
        <div></div>
      </section>

      <h2 className="gallery-title">Galerija slika</h2>
      <section className="gallery">
        <div className="item text">
          <h3>Naši radovi</h3>
          <p>
            Na fotografijama možete pogledati kako teku naši radovi i krajnje
            rezultate, koji su nas doveli do niza uspešno obavljenih poslova i
            mnogo zadovoljnih klijenata.
          </p>
          <Link to="galerija">
            <Button text="Vidi slike" icon="enter" />
          </Link>
        </div>

        <div className="item images">
          <div className="image">
            <img src={gal1} alt="parket" />
          </div>
          <div className="image">
            <img src={gal2} alt="parket" />
          </div>
          <div className="image">
            <img src={gal3} alt="parket" />
          </div>
        </div>
        <div className="item images">
          <div className="image">
            <img src={gal4} alt="parket" />
          </div>
          <div className="image">
            <img src={gal5} alt="parket" />
          </div>
          <div className="image">
            <img src={gal6} alt="parket" />
          </div>
        </div>

        <div className="item text">
          <h3>Pre i posle</h3>
          <p>
            Najbolji pokazatelj sjajnih rezultata našeg truda i rada su
            fotografije podova pre i nakon sređivanja.
          </p>
          <Link to="galerija">
            <Button text="Vidi slike" icon="enter" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default Home;
