import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import "./AService.css";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";

interface AServiceProps {
  image: string;
  text: React.ReactNode;
  title: string;
  topText: string;
}

const AService = (props: AServiceProps) => {
  const { image, text, title, topText } = props;

  const [expand, setExpand] = useState<boolean>(false);
  const serviceRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setExpand(!expand);

    if (expand) {
      serviceRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <div className="service" ref={serviceRef}>
      <h2>{title}</h2>
      <p className="intro">{topText}</p>
      <div className="content">
        <div className="image-border">
          <img src={image} alt={title} />
        </div>
        <div className="text-content">
          <div className={`description ${expand ? "expand" : ""}`}>{text}</div>
          <div
            className={`more ${expand ? "active" : ""}`}
            onClick={toggleExpand}
          >
            <span>{expand ? "Manje" : "Više"}</span>
            <Arrow />
          </div>

          <div className="actions">
            <Link to="kontakt">
              <Button text="Kontakt" icon="enter"></Button>
            </Link>
            <Link to={"galerija"}>
              <Button text="" icon="gallery" size="small"></Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AService;
