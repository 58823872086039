import "./TextField.css";

interface TextFieldProps {
  label: string;
  onChange: (e: any) => void;
  value: string;
  type?: "textfield" | "input";
  inputType?: "text" | "email";
  required?: boolean;
}
const TextField = (props: TextFieldProps) => {
  const {
    label,
    value,
    onChange,
    type = "input",
    inputType = "text",
    required = false,
  } = props;

  const renderTextField = () => {
    return (
      <textarea
        name={label}
        id={label}
        placeholder={label}
        rows={7}
        onChange={onChange}
        value={value}
      ></textarea>
    );
  };
  const renderInput = () => {
    return (
      <input
        type={inputType}
        placeholder={label}
        required={required}
        onChange={onChange}
        value={value}
      />
    );
  };

  return (
    <div className="field">
      {type === "textfield" ? renderTextField() : renderInput()}
    </div>
  );
};

export default TextField;
