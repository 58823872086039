import "./Footer.css";
import logo from "../../assets/full-logo.png";
import { ReactComponent as Email } from "../../assets/icons/mail.svg";
import { ReactComponent as Location } from "../../assets/icons/location.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  return (
    <footer>
      <div className="main">
        <div className="contact-info">
          <div className="item">
            <Email />
            <p className="text">
              <a href="mailto: parketvukasovic@gmail.com">
                parketvukasovic@gmail.com
              </a>
            </p>
          </div>
          <div className="item">
            <Phone />
            <p className="text">
              <a href="tel:+381628655652">062/865-56-52</a>
            </p>
          </div>
          <div className="item">
            <Location />
            <p className="text">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.google.rs/maps/place/Kornelija+Stankovića+29,+Novi+Sad/@45.2612468,19.8099698,17z/data=!3m1!4b1!4m5!3m4!1s0x475b11b231597dff:0x3439579e0219323b!8m2!3d45.261243!4d19.8121585"
              >
                Kornelija Stankovića 29, Novi Sad
              </a>
            </p>
          </div>
          <div className="socials">
            <a
              href="https://www.instagram.com/parket_vukasovic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://www.facebook.com/parketvukasovic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
          </div>
        </div>

        <div className="logo">
          <Link to="/">
            <img className="logo" src={logo} alt="Parket Vukasović" />
          </Link>
        </div>

        <nav>
          <Link
            to="/"
            className={`${location.pathname === "/" ? "active" : ""}`}
          >
            Početna
          </Link>
          <Link
            to="/o-nama"
            className={`${location.pathname === "/o-nama" ? "active" : ""}`}
          >
            O nama
          </Link>
          <Link
            to="/usluge"
            className={`${location.pathname === "/usluge" ? "active" : ""}`}
          >
            Usluge
          </Link>
          <Link
            to="/galerija"
            className={`${location.pathname === "/galerija" ? "active" : ""}`}
          >
            Galerija
          </Link>
          <Link
            to="/kontakt"
            className={`${location.pathname === "/kontakt" ? "active" : ""}`}
          >
            Kontakt
          </Link>
        </nav>
      </div>
      <div className="copy">
        <h5>© 2021 Parket Vukasović</h5>
      </div>
    </footer>
  );
};

export default Footer;
