import { Link, useLocation } from "react-router-dom";
import "./HamburgerMenu.css";
import logo from "../../assets/full-logo.png";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { useRef } from "react";

const HamburgerMenu = () => {
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);
  const closeMenu = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  return (
    <nav className={`hamburger`} role="navigation">
      <div id="menuToggle">
        <input type="checkbox" ref={inputRef} />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          <Link to="/" onClick={closeMenu}>
            <img src={logo} alt="Parket Vukasović" />
          </Link>
          <li className={`${location.pathname === "/" ? "active" : ""}`}>
            <Link to="/" onClick={closeMenu}>
              Početna
            </Link>
          </li>
          <li className={`${location.pathname === "/o-nama" ? "active" : ""}`}>
            <Link to="/o-nama" onClick={closeMenu}>
              O nama
            </Link>
          </li>
          <li className={`${location.pathname === "/usluge" ? "active" : ""}`}>
            <Link to="/usluge" onClick={closeMenu}>
              Usluge
            </Link>
          </li>
          <li
            className={`${location.pathname === "/galerija" ? "active" : ""}`}
          >
            <Link to="/galerija" onClick={closeMenu}>
              Galerija
            </Link>
          </li>
          <li className={`${location.pathname === "/kontakt" ? "active" : ""}`}>
            <Link to="/kontakt" onClick={closeMenu}>
              Kontakt
            </Link>
          </li>
          <div className="menu-socials">
            <a
              href="https://www.instagram.com/parket_vukasovic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://www.facebook.com/parketvukasovic/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default HamburgerMenu;
