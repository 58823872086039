import { Link } from "react-router-dom";
import "./NotFound.css";
import { ReactComponent as Lost } from "../../assets/images/lost.svg";

const NotFound = () => {
  return (
    <>
      <div className="lost-overlay"></div>
      <Lost className="lost" />
      <h1 className="not-found-title">Zalutali ste?</h1>
      <p className="not-found-text">Stranica koju tražite ne postoji.</p>
      <Link className="not-found-link" to="">
        Nazad na početnu
      </Link>
    </>
  );
};

export default NotFound;
