export const services = [
  {
    title: "Ugradnja",
    text: "Ugradnja svih vrsta drvenih podnih obloga - klasičnih i gotovih parketa, brodskih podova, laminata.",
    name: "ugradnja",
  },
  {
    title: "Reparacija i hoblovanje",
    text: "Popravka starog parketa, zamena oštećenih delova, učvršćivanje, brušenje, popunjavanje fugni i pukotina.",
    name: "reparacija",
  },
  {
    title: "Poliranje i lakiranje",
    text: "Mašinsko poliranje parketa do savršeno glatke površine, spremne za lakiranje. Lakiranje različitim vrstama lakova sa sjajnim, polumat ili mat završnim efektom.",
    name: "poliranje",
  },
  {
    title: "Ugradnja lajsni",
    text: "Postavljanje lajsni svih vrsta, veličina i materijala. Silikoniranje lajsni sa gornje i donje strane. ",
    name: "lajsne",
  },
  {
    title: "Bajcovanje",
    text: "Tehnika nijansiranja parketa pri kojoj se menja boja drveta, a njegova tekstura ostaje ista. Moguća je promena boje starog i novog parketa.",
    name: "bajcovanje",
  },
  {
    title: "Nivelacija poda",
    text: "Izlivanje samonivelišuće mase kojom se ravna podloga pre ugradnje podnih obloga. ",
    name: "nivelacija",
  },
];
