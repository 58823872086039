import { Route, Switch } from "react-router-dom";
import AboutUs from "./pages/AboutUs/AboutUs";
import Contact from "./pages/Contact/Contact";
import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import Services from "./pages/Services/Services";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/kontakt" exact>
        <Contact />
      </Route>
      <Route path="/usluge" exact>
        <Services />
      </Route>
      <Route path="/o-nama" exact>
        <AboutUs />
      </Route>
      <Route path="/galerija" exact>
        <Gallery />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
