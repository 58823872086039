import { useEffect, useState } from "react";
import "./Carousel.css";
import { ReactComponent as Arrow } from "../../assets/icons/play.svg";

interface CustomCarouselProps {
  items: any[];
  type: string;
}
const CustomCarousel = (props: CustomCarouselProps) => {
  const { items, type } = props;
  const [current, setCurrent] = useState<number>(0);
  const [firstLoad, setFirstLoad] = useState<boolean>(false);

  useEffect(() => {
    setFirstLoad(true);
  }, []);
  useEffect(() => {
    const itemElements = document.querySelector(`.items-${type}`)?.children;
    if (itemElements && firstLoad) {
      itemElements[current].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const handleOnDotClick = (index: number) => {
    const itemElements = document.querySelector(`.items-${type}`)?.children;
    if (itemElements && firstLoad) {
      itemElements[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
      setCurrent(index);
    }
  };

  const onArrowClick = (left: boolean) => {
    if (left) {
      const next = current > 0 ? current - 1 : items.length - 1;
      setCurrent(next);
    } else {
      const next = current < items.length - 1 ? current + 1 : 0;
      setCurrent(next);
    }
  };

  const renderDots = () => {
    return items.map((item, index) => (
      <button
        className={current === index ? "current" : ""}
        key={index}
        onClick={() => handleOnDotClick(index)}
      ></button>
    ));
  };
  return (
    <div className="carousel">
      <div className={`items items-${type}`}>
        {items.map((item, index) => (
          <div key={index} className="item">
            {item}
          </div>
        ))}
      </div>

      <div className="left">
        <button onClick={() => onArrowClick(true)}>
          <Arrow />
        </button>
      </div>
      <div className="right">
        <button onClick={() => onArrowClick(false)}>
          <Arrow />
        </button>
      </div>

      <div className="indicators">{renderDots()}</div>
    </div>
  );
};

export default CustomCarousel;
